import { ILanguage } from "./ILanguage";

export const languagePT: ILanguage = {
  COMMON: {
    ProjectName: "LAMP",
    Continue: "Continuar",
    Yes: "Sim",
    No: "Não",
    Cancel: "Cancelar",
    Create: "Criar",
    Add: "Adicionar",
    Import: "Importar",
    Change: "Alterar",
    Suspend: "Suspender",
    Save: "Guardar",
    Edit: "Editar",
    Delete: "Apagar",
    Remove: "Remover",
    Deactivate: "Desativar",
    Activate: "Ativar",
    Logout: "Terminar Sessão",
    Optional: "Opcional",
    Registar: "Registar",
    PrepositionForDateTime: " a ",
    CreatedBy: "Registado por",
    CreatedOn: "Registada a",
    All: "Todos",
    Clear: "Limpar",
    Deliver: "Entregar",
    OtherActions: "Outras ações",
    CloneAction: "Clonar Amostra",
    CloneRequestAction: "Clonar Pedido",
    CloneRequestSample: "Clonar Amostra",
    SelectOneOptionPlaceholder: "Selecione uma opção",
    Description: "Descrição",
    Name: "Nome",
    Back: "Voltar",

    FORM: {
      Details: "Detalhes",
      Search: "Pesquisar",
      SearchPlaceholder: "Pesquisar",
      InitialDate: "Data Inicial",
      InitialDatePlaceholder: "Data Inicial",
      InitialCreationDatePlaceholder: "Data Inicial de Criação",
      FinalDate: "Data Final",
      FinalDatePlaceholder: "Data Final",
      FinalCreationDatePlaceholder: "Data Fim de Criação",
      RequestDatePlaceholder: "Selecione uma data",
      DefaultPlaceholder: "Digite Aqui",
      SelectDefaultPlaceholder: "Digite para pesquisar",
      SelectStatusPlaceholder: "Selecione estados",
      Filters: "Filtros",
      CleanFilters: "Limpar Filtros",
      ClearSelection: "Limpar seleção",
      Sunday: "Do",
      Monday: "Se",
      Tuesday: "Te",
      Wednesday: "Qa",
      Thursday: "Qi",
      Friday: "Sx",
      Saturday: "Sa",
      MoreOptionsBtn: "Ver mais...",

      VALIDATIONS: {
        CannotBeEmpty: "Este campo não pode estar vazio.",
        RequiredField: "Este campo é obrigatório.",
        GuidValidator: "Formato GUID inválido.",
        SelectOneOption: "Selecione uma opção.",
        BatchFormatError: "Deverá conter o seguinte formato: ####### ou #######/# ou #######/##",
        FillMandatoryFields: "Por favor preencha os campos obrigatórios!",
        InvalidLocationId: "O ID da localização é inválido.",
        InvalidRequestId: "O ID do pedido é inválido.",
        PositiveNumber: "Insira um número inteiro",
        PositiveNumberOrDecimal: "Insira um número positivo / decimal",
        SelectDate: "Por favor selecione uma data",
      }
    },

    ERRORPAGES: {
      SomethingWrong: "Ocorreu um erro",
      BackToHome: "Página Inicial",
      AccessDenied: "Acesso Negado",
      AccessDeniedText1: "Não tem permissão para ver esta página!",
      AccessDeniedText2: "Por favor, verifique as suas credenciais e tente novamente.",
      PageNotFound: "Página Não Encontrada",
      PageNotFoundText: "Não conseguimos encontrar a página que procura.",
      SessionExpired: "Sessão Expirada",
      SessionExpiredText: "A sessão expirou devido à sua inatividade",
      Login: "Login",
    },

    POPUPS: {
      ImportOptions: "Opções de Importação",
      ExcelTemplate: "Template Excel",
      ExcelTemplateText: "Clique no botão abaixo para fazer o download de um ficheiro modelo de Excel. Este modelo contém a estrutura correta para a importação de dados.",
      Import: "Importar",
      ImportText: "Clique no botão abaixo para selecionar um ficheiro Excel e iniciar o processo de importação. Certifique-se que seleciona o ficheiro correto.",
    },
    SYSTEMPOPUPS: {
      Error: "Erro!",
      ErrorText: "",
      Success: "Sucesso!",
      SuccessText: "",
      Warning: "Aviso!",
      WarningText: "",
    },

    TABLE: {
      NoResults: "Nenhum resultado encontrado",
      NoResultsText: "Não encontramos o que procura, por favor procure novamente",
      RowsPerPage: "Items por pág.",
      Of: "de",
    }
  },

  SAMPLES: {
    Samples: "Amostras",
    DeliverSample: "Entregar Amostra",
    ViewSamplesOnHold: "Amostras Em Espera",
    ViewSamples: "Consultar Amostras",
    SamplesInLaboratory: "Amostras em Laboratório",
    LastSamplesDelivered: "Últimas Amostras Entregues",
    RejectSample: "Rejeitar Amostra",
    OnHoldSample: "Suspender Análise",
    SampleDetails: "Detalhes da Amostra",
    SampleData: "Dados da Amostra",
    DeliverNewSample: "Entregar Nova Amostra",
    MySamples: "As Minhas Amostras",
    ControlZeroFour: "Controlo 04",
    ChangeTo: "Alterar para",

    STATUS: {
      TotalNotFinished: "Total Não Concluídas",
      MultipleRegisted: "Registadas",
      Registed: "Registada",
      UnderAnalysis: "Em Análise",
      OnHold: "Em Espera",
      MultipleRejected: "Rejeitadas",
      Rejected: "Rejeitada",
      MultipleFinished: "Concluídas",
      Finished: "Concluída",
      WithRequestDate: "D. Requisição",
      WithRequestDateExtended: "Data de Requisição",
      TotalPending: "Total de pendentes",
      PendingWithRequestDate: "Pendentes com Data de Requisição",
      ProcessedCurrentYear: "Processadas no Ano Corrente"
    },

    INFOANDFORM: {
      RegistrationDate: "D. Registo",
      RemoveCorrelationConfirmText: "Tem a certeza que pretende remover esta correlação entre amostras?",
      RemoveCorrelationSuccessMessage: "Correlação removida.",
      RemoveCorrelation: "Remover Correlação",
      RemoveCorrelationAbrev: "Remover",
      Section: "Secção",
      Employee: "Operador",
      EmployeePlaceholder: "Selecione uma opção",
      AreaPlaceholder: "Selecione o tipo de àrea",
      Material: "Material",
      MaterialPlaceholder: "Digite um material",
      SAPMaterial: "Material SAP",
      SAPMaterialPlaceholder: "Selecione um material",
      Batch: "Lote",
      BatchPlaceholder: "Ex: 6258559",
      Name: "Nome",
      NamePlaceholder: "Digite o seu nome",
      Type: "Tipo",
      TypePlaceholder: "Selecione o tipo",
      Description: "Descrição",
      DescriptionPlaceholder: "",
      Analyst: "Analista",
      SelectAnalyst: "Selecione Analista",
      AnalystPlaceholder: "",
      CreationDate: "D. Criação",
      RequestDate: "Data de Requisição",
      RequestDateSummary: "D. Requisição",
      OnHoldReason: "Razão de espera",
      RejectReason: "Razão de rejeição",
      Status: "Estado",
      TimeToEdit: "Tempo para editar ",
      ProductionOrder: "Ordem de Produção",
      ProductionOrderPlaceholder: "Ex: 6262292",
      Observations: "Observações",
      ObservationsPlaceholder: "Digite as suas observações",
      RequestedBy: "Requisitado por",
      Waybill: "Carta de Porte",
      WaybillPlaceholder: "Ex: WB2023/01",
      Area: "Área",
      Supplier: "Fornecedor",
      SupplierPlaceholder: "",
      NumSpools: "Nº Bobines",
      NumSpoolsPlaceholder: "",
      Facility: "Unidade",
      FacilityPlaceholder: "Selecione uma unidade",
      Reason: "Razão",
      SelectReasonPlaceholder: "Selecione uma razão",
      ExecuteControlZeroFour: "Executar Controlo 04",
      LastControlZeroFour: "Última execução",
      NoControls04Found: "Controlo 04 irá ser executado pela primeira vez neste material.",
      Control04Active: "C04 Ativado",
      Control04ActiveTooltip: "Controlo 04 Ativado",
      Control04Needed: "C04 Necessário",
      Control04NeededTooltip: "Control 04 Necessário",
      Control04: "Controlo 04",
      Repeated: "Amostra Repetida",
      Source: "Origem",
      SourcePlaceholder: "Ex: VLMT Guangzhou",
      ExecutedOn: "Executado a",
      Pending: "Pendentes",
      PendingControls04: "Controlos 04 Pendentes",
      LastControl04: "Último Controlo 04",
      Clone: "Clonagem",
      Manufacturer: "Fabricante",
      Used: "Usado",
      AssociatedRequest: "Pedido Associado",
      RequestSampleData: "Dados da Amostra do Pedido",

    },

    TYPES: {
      Production: "Produção",
      Fibers: "Fibras",
      Produced: "Produzidos / Acabados Fora",
      Accessories: "Acessórios",
      Threads: "Fios",
      Others: "Outros",
      Package: "Pacote"
    },
    TYPESENUM: {
      PRODUCTION: "Produção",
      FIBERS: "Fibras",
      PRODUCED: "Produzidos / Acabados Fora",
      ACCESSORIES: "Acessórios",
      THREADS: "Fios",
      OTHERS: "Outros",
      PACKAGE: "Pacote"
    },

    PACKAGEAREATYPES: {
      DF: "DF",
      Dryer: "Dryer",
      LP: "LP",
      Minning: "Minning",
      Pulp: "Pulp"
    },
    OTHERTYPESENUM: {
      ACCESSORIES: "Acessórios",
      FABRICS: "Tecidos",
      FELTS: "Feltros",
      FIBERS: "Fibras",
      THREADS: "Fios",
      OTHERS: "Outros"
    },

    ACTIONS: {
      Analyse: "Analisar",
      Reject: "Rejeitar",
      OnHold: "Em Espera",
      Finish: "Concluir",
      Revert: "Reverter para Registada",
    },

    POPUPS: {
      PossibleCorrelationFoundText: "Uma possível correlação foi encontrada. Pretende correlacionar?",
      CorrelateSampleConfirmText: "Pretende correlacionar a amostra recém criada?",
      CreateSampleSuccess: "Amostra criada.",
      UpdateSampleSuccess: "Amostra alterada.",
      UpdateRequisitionDateSuccess: "Data de requisição adicionada",
      ChangeSampleStatusConfirmText: "Tem a certeza que pretende alterar o estado da amostra para",
      RevertSampleStatusConfirmText: "Tem a certeza que pretende alterar o estado da amostra para Registada?",
      EditSampleDetails: "Editar Amostra",
      EditSampleDetailsSuccess: "Dados da amostras editados!",
      EditSampleDetailsError: "Erro ao editar os dados da amostra!",
      StatusChangedSuccess: "Estado da amostra alterado.",
      DELIVERPRODUCTION: {
        ProductionOrderFormatError: "Deverá conter sete caracteres!",

      }
    }
  },

  REQUESTS: {
    New: "Novo",
    Used: "Usado",
    Requests: "Pedidos",
    AddNewSample: "Adicionar Amostra de Pedido",
    ViewRequests: "Consultar Pedidos",
    CreateRequest: "Registar Pedido",
    RequestDetails: "Detalhes do Pedido",
    CustomerData: "Dados do Cliente",
    OtherData: "Outros Dados",
    RequestData: "Dados do Pedido",
    SubmitRequest: "Formalizar Pedido",
    Pending: "Pendentes",
    CloseRequest: "Fechar Pedido",
    RequestClosed: "Pedido Terminado",
    ReopenRequest: "Reabrir Pedido",
    RequestReopened: "Pedido Reaberto",
    MyRequests: "Os Meus Pedidos",
    ContactsWarning: "Verifique sempre que o e-mail está correto antes de adicionar.",


    NOTIFICATIONS: {
      Notify: "Notificar",
      NotificationSent: "Notificação Enviada",
      NotifyReportCompletedTooltip: "Notificar que o Relatório está concluído",
      RequestReportCompletedConfirmText: "Tem a certeza que pretende notificar o autor do pedido e respetivos contactos associados do pedido, informando a conclusão do relatório?"
    },

    CLIENTREGISTRATIONENUM: {
      NotRegistered: "Cliente não registado",
      CompassRegistered: "Registado no Compass",
      SAPRegistered: "Registado no SAP"
    },


    ANALYSISREASONTYPE: {
      AnalysisReasonType: "Tipo da razão de análise",
      Competitor: "Concorrência",
      Complaint: "Reclamação",
      Development: "Desenvolvimento",
      EndLife: "Fim de Vida",
      Others: "Outros"
    },


    STATUS: {
      TotalNotFinished: "Total Não Concluídos",
      Pending: "Pendentes",
      OnHold: "Em Espera",

      Registed: "Registados",
      RegistedSingular: "Registado",
      Canceled: "Cancelados",
      CanceledSingular: "Cancelado",
      Draft: "Rascunhos",
      DraftSingular: "Rascunho",
      UnderAnalysisSingular: "Em análise",
      UnderAnalysis: "Em Análise",
      Finished: "Fechados",
      FinishedSingular: "Fechado",

      ReadyToClose: "Concluídos", //Concluido
      ReadyToCloseSingular: "Concluído"
    },



    TYPES: {
      AR: "AR",
      WF: "WF",
      DF: "DF",
      PP: "PP",
      DRYER: "DRYER",
      Dryer: "Dryer",
    },

    INFOANDFORM: {
      RequestType: "Tipo de Pedido",
      Reference: "N.º do Pedido",
      KetoProject: "Projeto Keto",
      KetoProjectPlaceholder: "Digite o Projeto Keto",
      MaterialReferencePlaceholder: "Digite a Ref. do material",
      Sample: "Amostra",
      CarginogenicSubstances: "Substâncias carcinogénicas",
      HazardousMaterials: "Materiais perigosos",
      DeliveredByCourier: "Entregue por correio",
      DeliveredPersonally: "Entregue pessoalmente",
      Transport: "Transporte",
      PerformanceComments: "Comentários sobre o tecido, performance, etc",
      RemovalReason: "Razão de remoção",
      RemovalReasonPlaceholder: "Digite a razão de remoção",
      InstalledDate: "Data de instalação",
      InstalledDatePlaceholder: "Selecione a data de instalação",
      RemovedDate: "Data de remoção",
      RemovedDatePlaceholder: "Selecione a data de remoção",
      RunningTime: "Tempo de execução",
      ProvideSlurrySafetySheet: "FORNEÇA A FOLHA DE DADOS DE SEGURANÇA DO MATERIAL DA PASTA, SE POSSÍVEL",
      MachineInformation: "Informação da Máquina",
      PositionMachine: "N.º da posição da Máquina",
      PositionMachinePlaceholder: "Digite o n.º da Posição",
      Position: "Posição",
      PositionPlaceholder: "Digite a posição",
      Description: "Descrição",
      Factory: "Fabricante",
      Duration: "Duração",
      DurationPlaceholder: "Digite a duração",
      FactoryPlaceholder: "Digite o fabricante",
      Client: "Cliente",
      Name: "Assunto",
      NamePlaceholder: "Digite o assunto do pedido",
      Type: "Tipo",
      NumSamples: "Nº Amostras",
      Requester: "Requisitante",
      Analyst: "Analista",

      CreationDate: "Data de Criação",
      FormalizingDate: "Data de Formalização",
      Status: "Estado",
      Company: "Empresa",
      CompanyPlaceholder: "Digite o nome da empresa",
      Industry: "Indústria",
      IndustryPlaceholder: "Digite a indústria",
      ProductDescription: "Descrição do Produto",
      ProductDescriptionPlaceholder: "Digite a descrição do produto",
      ClientNumber: "Número do Cliente",
      ClientNumberPlaceholder: "Digite o número do cliente",
      Manufacturer: "Fabricante",
      ManufacturerPlaceholder: "Digite o fabricante",
      Material: "Material",
      MaterialPlaceholder: "Digite o material",
      MaterialStatusPlaceholder: "Selecione o estado do material",
      Other: "Outro",
      OtherPlaceholder: "Digital qual",
      SAPMaterial: "Material SAP",
      SAPMaterialPlaceholder: "Selecione um material",
      New: "Novo",
      CompareWith: "Comparar com",
      CompareWithPlaceholder: "Selecione uma opção",
      Observations: "Observações",
      ObservationsPlaceholder: "Digite as suas observações",
      Comments: "Comentários",
      CommentsPlaceholder: "Digite os seus comentários",
      NameDetailText: "Nome introduzido por quem registou o pedido",
      DescriptionDetailText: "Descrição introduzida por quem registou o pedido",
      AwaitingReceipt: "Aguarda Receção",
      TestsSamplesAssociations: "Associações Testes/Amostras",
      Cost: "Custo",
      TotalCost: "Custo Total",
      Attachments: "Anexos",
      FileName: "Nome do Ficheiro",
      UsedMaterial: "Material usado",
      Waybill: "Carta de Porte",
      WaybillAbrev: "C. Porte",
      WaybillPlaceholder: "Digite a Carta de Porte",
      MaterialDescription: "Material/Descrição",
      MaterialDescriptionPlaceholder: "Selecione um material",
      TargetSAP: "Target SAP",
      TechNote: "Nota técnica",
      TargetOrTechNote: "Target SAP ou Nota Técnica",
      TargetOrTechNotePlaceholder: "Selecione uma opção",
      MaterialStatus: "Estado do Material",
      AnalysisReason: "Razão da Análise",
      AnalysisReasonPlaceholder: "Digite uma descrição para a razão da análise",
      RegistedOnCompass: "Registado no Compass",
      SAPNumberOrCompass: "Número SAP/Compass",
      SAPNumberOrCompassPlaceholder: "Digite o número SAP/Compass",
      Shipping: "Transporte",
      ShippingMethod: "Modo de envio",
      DeliverByCourier: "Entregar por transportadora",
      DeliverPersonally: "Entregar pessoalmente",
      EquipmentInformation: "Informação do Equipamento",
      IndustrialSectorDescription: "Outro Setor Industrial",
      IndustrialSector: "Setor Industrial",
      FilterType: "Tipo de Filtro",
      FilterTypeDescription: "Outro Tipo de Filtro",
      Slurry: "Pasta",
      SlurryPlaceholder: "Forneça mais informações",
      ContainsCarcinogenicSubstancesPlaceholder: "Digite as substâncias carcinogénicas",
      ContainsHazardMaterialPlaceholder: "Digite o material perigoso",
      ContainsHazardMaterial: "Processo contém materiais perigosos",
      ContainsCarcinogenicSubstances: "Processo contém carcinogénicos",
      FurtherInformation: "Outras informações",
      FurtherInformationPlaceholder: "Forneça mais informações",
      AddedAttachmentBy: "Adicionado por",
      Clone: "Clonagem",
      CancelReason: "Razão de cancelamento",
      AssociateTestsWarning: "Para testes de resistência e encolhimento, o tamanho recomendado para a realização deste teste é 50cm a toda a largura ou 2 amostras A3",
    },

    INFOANDFORM_2: {
      Draft: "Rascunho",
      DraftPlural: "Rascunhos",
      NewAttachmentAdded: "Novo anexo adicionado!",
      DeleteAttachment: "Remover Anexo",
      DeleteAttachmentConfirmText: "Tem a certeza que pretende remover o anexo '{0}'? Os dados serão removidos permanentemente!",
      DeleteAttachmentSuccess: "Anexo removido.",
      Contacts: "Contactos",
      EmailPlaceholder: "Digite o email do contacto",
      NamePlaceholder: "Digite o nome do contacto",
      Email: "Email",
      DeleteContact: "Remover Contacto",
      DeleteContactConfirmText: "Tem a certeza que pretende remover o contacto com email '{0}'?",
      DeleteContactSuccess: "Contacto removido.",
      TestRemoved: "Teste removido.",
      ClientRegistration: "Registo do Cliente",
      AddSampleSuccess: "Nova amostra adicionada!",
      DestroysSample: "Destruição da Amostra",
      CorrelatedSample: "Amostra Correlacionada",
      DraftCreatedBy: "Rascunho criado por",
      RequestFormalized: "Pedido formalizado por",
      AssociatedTests: "Testes associados"

    },

    ACTIONS: {
      AddTests: "Adicionar Testes ao Pedido",
      AddSample: "Adicionar Amostra de Pedido",
      AddAttachments: "Adicionar Anexos",
      AddContacts: "Adicionar Contactos"
    },

    POPUPS: {
      MaterialRef: "Referência do Material",
      SampleType: "Tipo de Amostra",
      Observations: "Observações",
      Waybill: "Carta de Porte",
      RemoveCorrelationConfirmText: "Tem a certeza que pretende remover a correlação desta amostra?",
      RemoveCorrelationSuccess: "Correlação de amostra removida!",
      AssociateTestsWithSample: "Associar Testes a Amostra",
      SampleStatus: "Estado da Amostra",
      RemoveSample: "Remover Amostra",
      EditSample: "Editar Amostra",
      RemoveTest: "Remover Teste",
      CloneSampleConfirmText: "Tem a certeza que pretende clonar esta amostra para este Pedido?",
      RemoveSampleConfirmText: "Tem a certeza que pretende remover a amostra com o material '{0}'? Informação da amostra será perdida!",
      RemoveTestConfirmText: "Tem a certeza que pretende remover o teste com o nome '{0}'? Informação do teste será perdida!",
      AddContact: "Adicionar Contacto",
      CancelTestConfirmText: "Tem a certeza que pretende cancelar o Pedido? Toda a informação será perdida.",
      SubmitRequestConfirmText: "Tem a certeza que pretende formalizar o Pedido?",
      Reason: "Razão",
      ReasonPlaceholder: "Digita uma razão para cancelar o Pedido",
      CancelRequest: "Cancelar Pedido",
      CancelReq: "Cancelar Ped.",
      CancelRequestSuccess: "Pedido cancelado!",
      SubmitRequest: "Formalizar Pedido",
      SubmitRequestSuccess: "Pedido formalizado!",
      CreateRequestSuccess: "Pedido criado.",
      TestSampleAssociation: "Associar Testes a Amostra",
      SelectedSample: "Amostra Selecionada",
      NumSelectedTests: "Nº Testes Selecionados",
      Associate: "Associar",
      CorrelateSample: "Correlacionar Amostra",
      SelectSampleToCorrelate: "Selecione a amostra a correlacionar",
      RemoveCorrelation: "Remover Correlação",
      Correlate: "Correlacionar",
      TestAddedToRequestSuccess: "Teste associado ao pedido!",
      NoSelectedTests: "Nº testes selecionados",
      TotalCost: "Custo Total",
      EditRequestDetails: "Editar Pedido",
      EditRequestDetailsSuccess: "Pedido atualizado.",
    },
  },

  GLOBALADMIN: {
    GlobalAdmin: "Administração global",
    EMAILS: {
      Email: "Email",
      Emails: "Emails",
      LaboratoryEmail: "Email do Laboratório",
      DistributionLaboratoryEmails: "Emails de Distribuição do Laboratório",
      DistributionLaboratoryEmailsPageExplanation: "Emails de distribuição do laboratório utilizados para envio de alertas",
      EmailDetails: "Detalhes do(s) email(s)",
      InputEmailValidation: "Input inválido. Siga as recomendações.",
      UpdateEmailDetails: "Editar Email(s) do Laboratório",
      UpdateEmailsInstruction: "Para mais do que um email, utilize o símbolo de separação ;",
      UpdateEmailsInstructionExample: "Exemplo: lab_email001@valmet.com; lab_email002@valmet.com",
      UpdateEmailDetailsSuccessMessage: "Email(s) de laboratório atualizados."
    },

    EMPLOYEES: {
      Employees: "Operadores",
      LabOperators: "Operadores de Laboratório",
      LabOperator: "Operador de Laboratório",

      INFOANDFORM: {
        Status: "Estado",
        AddLabOperator: "Adicionar Operador",
        EditLabOperator: "Editar Operador",
        Code: "Código",
        Division: "Secção",
        Name: "Nome",
        EmployeeDetails: "Detalhes do Operador",
        Disabled: "Desativo",
        Enabled: "Ativo"
      },
      MESSAGES: {
        ImportFileSuccess: "Importação do ficheiro concluída",
        EditOperatorSuccess: "Operador editado",
        AddOperatorSuccess: "Operador adicionado",
        DisableEmployeeConfirmText: "Tem a certeza que pretende desativar '{0}'?",
        EnableEmployeeConfirmText: "Tem a certeza que pretende ativar '{0}'?",
        EmployeeStatusSuccess: "Estado {0}!"
      },
      INFO: {
        Enabled: "Ativado",
        Disabled: "Desativado",
      }
    },
    LOCATIONS: {
      Locations: "Localizações",
      CreateLocation: "Criar Localização",
      AccessGroups: "Grupos de Acesso",

      INFO: {
        Name: "Nome",
        NamePlaceholder: "Digite o nome da localização",
        NumGroups: "Nº Grupos",
        Type: "Tipo",
      },

      POPUP: {
        Activate: "Ativar",
        ActivateConfirmText: "Tem a certeza que quer ativar a localização?",
        ActivateLocationError: "Erro ao ativar localização!",
        ActivateLocationSuccess: "Localização ativada!",
        CreateLocationSuccess: "Localização criada!",
        EditLocation: "Editar Localização",
        EditLocationError: "Erro ao editar localização!",
        EditLocationSuccess: "Localização editada!",
        DeactivateLocationError: "Erro ao desativar localização!",
        Deactivate: "Desativar",
        DeactivateConfirmText: "Tem a certeza que quer desativar a localização?",
        DeactivateLocationSuccess: "Location deactivated!"
      },

      ACCESSGROUPS: {
        Type: "Tipo",
        Name: "Nome",
      },
    },


    TESTTYPES: {
      TestTypes: "Tipos de Teste",
      INFOANDFORM: {
        Name: "Nome",
        NamePT: "Nome (pt-PT)",
        NameEN: "Nome (en-EN)",
        Description: "Descrição",
        CreateTestType: "Criar Tipo de Teste",
        EditTestType: "Editar Tipo de Teste",
        NamePlaceholder: "Ex: Feltros"
      },
      POPUP: {
        Activate: "Ativar",
        ActivateConfirmText: "Tem a certeza que pretende ativar este tipo de teste?",
        ActivateTestTypeSuccess: "Tipo de teste ativado.",
        CreateTestTypeSuccess: "Tipo de teste criado",
        EditTestTypeSuccess: "Tipo de teste editado!",
        Deactivate: "Desativar",
        DeactivateConfirmText: "Tem a certeza que pretende desativar este tipo de teste?",
        DeactivateTestTypeSuccess: "Tipo de teste desativado!"
      },
    },

    FACILITIES: {
      CreateFacility: "Criar Unidade",
      Facilities: "Unidades",
      INFOANDFORM: {
        Name: "Nome",
      },
      POPUP: {
        ActivateFacilityConfirmText: "Tem a certeza que quer ativar a unidade?",
        ActivateFacilitySuccess: "Unidade ativada!",
        ActivateFacilityError: "Erro ao ativar a unidade!",
        CreateFacilitySuccess: "Unidade criada!",
        DeactivateFacilityError: "Erro ao desativar unidade!",
        DeactivateFacilityConfirmText: "Tem a certeza que quer desativar a unidade?",
        DeactivateFacilitySuccess: "Unidade desativada!",
        Deactivate: "Desativar",
        EditFacilityError: "Erro ao editar a unidade!",
        EditFacility: "Editar Unidade",
        EditFacilitySuccess: "Unidade editada!",
        LoadingFacilityError: "Erro ao carregar a informação da unidade!"
      }
    },
    MATERIALS: {
      Materials: "Materiais",
      INFO: {
        Plant: "Localização",
        Name: "Nome",
        Code: "Código do Material",
        Description: "Descrição",
        SubjectToC04: "Sujeito a Controlo 04",
        RemoveMaterialToC04: "Remover sujeição do material a Controlo 04",
        Control04: "Controlo 04",
        Status: "Estado",
        SubjectMaterialToC04: "Sujeitar Material a Controlo 04",

        RemoveSubjection: "Remover sujeição",
        Subject: "Sujeitar",
        RemoveSubjectMaterialToC04ConfirmMessage: "Tem a certeza que prentede remover a sujeição deste material ao Controlo 04?",
        RemoveSubjectMaterialToC04SuccessMessage: "Sujeição ao Controlo 04 removida.",
        SubjectMaterialToC04ConfirmMessage: "Tem a certeza que prentede sujeitar este material ao Controlo 04?",
        SubjectMaterialToC04SuccessMessage: "Sujeição ao Controlo 04 adicionada."
      },
      POPUP: {
        CreateMaterial: "Criar Material",
        CreateMaterialSuccess: "Material criado!",
        EditMaterial: "Editar Material",
        EditMaterialSuccess: "Material editado!",
        DeleteMaterialConfirmText: "Tem a certeza que quer apagar o material?",
        DeactivateMaterialConfirmText: "Tem a certeza que quer desativar o material?",
        Deactivate: "Desativar",
        ActivateMaterialConfirmText: "Tem a certeza que quer ativar o material?",
        DisableMaterialSuccess: "Material desativado!",
        ActivateMaterialSuccess: "Material ativado",
        ImportFileSuccess: "Importação do ficheiro concluída",
      }
    },

    ACCESSGROUPS:
    {
      Type: "Tipo",
      ID: "ID",
      Name: "Nome",
      EditedGroupSuccess: "Grupos de acesso editados!",
      AccessGroups: "Grupos de Acesso",
      SubmitError: "Por favor, verifique os erros no formulário!",
      TYPES: {
        ACCESS: "Acesso Geral",
        REGISTER_SAMPLES: "Entregar Amostra",
        ADMIN: "Administrador Local",
        ANALISTS: "Analistas",
        ANALISYS_REQUEST_GLOBAL: "Supervisão de Pedidos",
        AR: "AR",
        DF: "DF",
        DRYER: "Dryer",
        PP: "PP",
        WF: "WF"
      }
    },



    TESTS: {
      Tests: "Testes",

      INFO: {
        DestroysSample: "Amostra será destruída",
        FilteredByNonUsed: "Testes disponíveis para amostras não usadas",
        FilteredByUsed: "Testes disponíveis para amostras usadas",
        Type: "Tipo",
        Usage: "Usado",
        Essays: "Ensaios",
        EssaysPlaceholder: "Ex: Titulo/DTex",
        Tests: "Tests",
        TestsPlaceholder: "Ex: Titulo/DTex",
        Multiplier: "Multiplicador DF",
        MultiplierSimplified: "Multi. DF",
        MultiplierPlaceholder: "Ex: ",
        RehearsalTime: "T. Ens (h)",
        RehearsalTimePlaceholder: "Ex: 0.2",
        RehearsalTimeSimplified: "T. Ens.",
        WorkedHours: "H. Trab (h)",
        WorkedHoursSimplified: "H. Trab",
        WorkedHoursPlaceholder: "Ex: 0.2",
        SampleSize: "Tamanho Amostra",
        SampleSizePlaceholder: "Ex: 10 x 10 mm",
        DestroyingBarOrFilter: "Dest. Amostra",
        DestroyingBarOrFilterPlaceholder: "Destruição da Amostra",
        Cost: "Custo (€)",
        CostsSimplified: "Custos",
        CostPlaceholder: "Ex: 10.5",
        UsedCost: "Custo Usado (€)",
        UsedCostPlaceholder: "Ex: 10.5",
        Procedure: "Procedimentos",
        InternalProcedureSimplified: "Proc. interno",
        InternalProcedure: "Procedimento Interno",
        InternalProcedurePlaceholder: "Ex: IN S6 104",
        Standard: "Norma",
        StandardPlaceholder: "Ex: ISO 17202 / ISO 2061",
        Used: "Usado",
        Duration: "Duração",
        Name: "Nome",
        NamePlaceholder: "Digite o nome do teste",
        Measurement: "Medida",
        MeasurementType: "Tipo de medida",
        MeasurementPlaceholder: "Selecione o tipo",
        WidthPlaceholder: "Digite a largura",
        Area: "Área",
        Length: "Comprimento",
        LengthPlaceholder: "Digite o comprimento",
        Width: "Largura",
        Units: "Unidade",
        UnitsPlaceholder: "Selecione a unidade",
      },

      POPUP: {
        Name: "Nome",
        NamePlaceholder: "Ex: Teste 01",
        CreateTest: "Criar Teste",
        CreateTestSuccess: "Teste criado!",
        EditTest: "Editar Teste",
        EditTestSuccess: "Teste editado!",
        DeleteTestConfirmText: "Tem a certeza que quer apagar o teste?",
        DeactivateTestConfirmText: "Tem a certeza que quer desativar o teste?",
        Deactivate: "Desativar",
        ActivateTestConfirmText: "Tem a certeza que quer ativar o teste?",
        DisableTestSuccess: "Teste desativado!",
        ActivateTestSuccess: "Teste ativado",
        ImportFileSuccess: "Import File concluded",
      },

      TYPES: {
        Fabrics: "Tecidos",
        Wires: "Fios",
        Felts: "Feltros",
        Others: "Outros",
      },

      TYPESENUM: {
        FABRICS: "Tecidos",
        WIRES: "Fios",
        FELTS: "Feltros",
        OTHERS: "Outros",
      }
    },
  },
};
